import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { BaseResponseModel } from '../models/base-response-model';
//import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { ConfigurationHelper } from '../helpers/configuration-helper';
import { NewsEvent } from '../models/news-event';
import { Observable } from 'rxjs/Observable';
import 'rxjs/Rx';


@Injectable({
  providedIn: 'root'
})

export class PagesService {


  //httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  //newsList: any = [];
  newsList: NewsEvent[];
  constructor(private httpClient: HttpClient, private auth: AuthService) {
    this.newsList = [];
  }

  /***
     * contactus
     * to contact us information
  ***/
  contactus(contactusModel: any) {
    return this.httpClient.post(environment.domain + 'api/ContactUs/contactus', contactusModel, this.auth.setRequestHttpHeadersForWithoutToken()).pipe(map(data => { return data; }));
  }

  /***
   * subscription
   * to subscribe email
   ***/
  subscription(email: any) {
    return this.httpClient.post(environment.domain + 'api/ContactUs/subscribe/' + email, this.auth.setRequestHttpHeadersForWithoutToken()).pipe(map(data => { return data; }));
  }

  /***
   * donate
   * to donation information
   ***/
  donate(donationModel: any) {
    return this.httpClient.post(environment.domain + 'api/Donation/donate', donationModel, this.auth.setRequestHttpHeadersForWithoutToken()).pipe(map(data => { return data; }));
  }

  donatewithPaypal(paypalResponseModel: any) {
    return this.httpClient.post(environment.domain + 'api/Donation/DonateWithPayPal', paypalResponseModel, this.auth.setRequestHttpHeadersForWithoutToken()).pipe(map(data => { return data; }));
  }

  getDetailedNews(id: number): Observable<any> {
    return ConfigurationHelper.Configuration.NewEvents.filter(item => (item.Id === id))[0] as any;
  }

  transform(value: string, length: number): string {
    const biggestWord = 50;
    const elipses = "...";
    if (typeof value === "undefined") return value;
    if (value.length <= length) return value;
    //.. truncate to about correct lenght
    let truncatedText = value.slice(0, length + biggestWord);
    //.. now nibble ends till correct length
    while (truncatedText.length > length - elipses.length) {
      let lastSpace = truncatedText.lastIndexOf(" ");
      if (lastSpace === -1) break;
      truncatedText = truncatedText.slice(0, lastSpace).replace(/[!,.?;:]$/, '');
    };
    return truncatedText + elipses;
  }


}
