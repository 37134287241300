import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ExceptionhandlerService {

  constructor(private router: Router, private toastrService: ToastrService) {

  }

  handleExcption(error: any) {
    // this.loader.loader = false;
    if (error.status == 401) {
      //localStorage.removeItem(ConfigurationModel.Configuration.USER_PROFILE);
      //localStorage.removeItem(ConfigurationModel.Configuration.FARMERID);
      this.router.navigate(['/auth/login']);
    }
    else if (error.status == 404) {
      this.router.navigate(['/pages/miscellaneous/404']);
      //will add new exception page.
    }
    else if (error.status == 500) {
      if (error._body != null) {
        let newerror = JSON.parse(error._body);
        this.toastrService.error(newerror.error_description, "Error");
      }
      else
        this.toastrService.error("Somthing went wrong!", "Error");
    }
    else if (error != null) {
      this.toastrService.error(error.statusText, "Error");
    }
  }
}
