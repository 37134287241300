<!-- Preloader Start -->
<div id="loader" class="se-pre-con" style="opacity:75%;"></div>
<!-- Preloader Ends -->

<header id="home">
  <!--Start Navigation-->
  <nav class="navbar navbar-default bootsnav on no-full">
    <div class="container-full">
      <div class="attr-nav border-less" *ngIf="(router.url != '/donate')">
        <ul *ngIf="!router.url.includes('paymentwithpaypal')">
          <li class="button" *ngIf="!router.url.includes('paymentcancel')">
            <button class="btn btn-success" routerLink="./donate"><i class="fas fa-heart"></i> Donate</button>
          </li>
        </ul>
      </div>
      <div class="navbar-header">
        <button *ngIf="!navbarOpen" id="btnnav" type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu" (click)="toggleNavbar()">
          <i class="fa fa-bars"></i>
        </button>
        <button *ngIf="navbarOpen" type="button" class="navbar-toggle" (click)="closeNavBar()">
          <i class="fa fa-times"></i>
        </button>

        <a class="navbar-brand" href="">
          <img src="assets/img/rehabnova-logo.jpg" class="logo" alt="Logo">
        </a>
      </div>
      <div class="collapse navbar-collapse" id="navbar-menu">
        <ul class="nav navbar-nav navbar-center" data-in="fadeInDown" data-out="fadeOutUp">
          <li [routerLinkActive]="['active']">
            <a routerLink="./home">Home</a>
          </li>
          <li [routerLinkActive]="['active']">
            <a routerLink="./aboutus">About Us</a>
          </li>
          <li [routerLinkActive]="['active']">
            <a routerLink="./ourprograms">Our Programs</a>
          </li>
          <li [routerLinkActive]="['active']">
            <a routerLink="./gallery">Gallery</a>
          </li>
          <li [routerLinkActive]="['active']">
            <a routerLink="./news">News & Events</a>
          </li>

          <li [routerLinkActive]="['active']">
            <a routerLink="./howyoucanhelp">How You Can Help?</a>
          </li>

          <li [routerLinkActive]="['active']">
            <a routerLink="./contactus">Contact</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!-- End Navigation -->
</header>
<!-- End Header -->

<router-outlet></router-outlet>

<footer class="bg-dark text-light">
  <!-- Fixed Shape -->
  <div class="fixed-shape">
    <img src="assets/img/footer-bg.png" alt="Shape">
  </div>
  <!-- Fixed Shape -->
  <div class="container">
    <div class="f-items default-padding">
      <div class="row">
        <div class="col-lg-4 col-md-6 item">
          <div class="f-item about">
            <img src="assets/img/LogoRehabNova-yellow.png" alt="Logo">
            <!--<p>
              RehabNOVA - a call for sustainability through the creation of “built environments” that will greatly enhance life cycles of developmental and capacity building programs in Sub-Saharan Africa.
            </p>-->
            <p>
              RehabNOVA - The organization’s vision is to help build prosperous communities across Sub-Saharan Africa, where people have a say in their communities through promoting creativity, innovation and independence.
            </p>
            <form style="">
              <!--action="contact.html#"-->
              <input [(ngModel)]="subscribeModel.Email" #email="ngModel" id="email" name="email" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" type="email" placeholder="Your Email" class="form-control">

              <span class="alert-error validation-error-margin" *ngIf="email.invalid && isFormSubmitted && email.errors.required">Please enter email</span>
              <span class="alert-error validation-error-margin" *ngIf="email.invalid && isFormSubmitted && email.errors.pattern">Please enter valid email</span>
              <button type="submit" name="subscribe" id="subscribe" (click)="subscribe(email)">
                Subscribe
              </button>
            </form>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 item">
          <div class="f-item">
            <h4 class="widget-title">Contact Info</h4>
            <div class="address">
              <ul>
                <li>
                  <!--<strong>Address:</strong> 525 South Hewitt Street, Los Angeles, Ca 90013, United States-->
                  <strong>Address:</strong>2363 Kendal Green Circle,College Station, Tx 77845, United States
                </li>
                <li>
                  <strong>Email:</strong>
                  <a href="mailto:info@rehabnova.org">info@rehabnova.org</a>

                </li>
                <li>
                  <strong>Phone:</strong>
                  <a href="tel:+1 (979)777-6991">+1 (979)777-6991</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 item">
          <div class="f-item recent-post">
            <h4 class="widget-title">Latest News</h4>
            <div class="item" *ngFor="let news of newsList">
              <!--<a routerLink="./news/newsdetails" [queryParams]="{id:news.Id}" queryParamsHandling="merge">{{news.TitleEllipse}}</a>-->
              <a href="javascript:void(0)" (click)="redirectTo(news.Id)">{{news.TitleEllipse}}</a>
              <span *ngIf="news.Date!=''"><i class="fas fa-calendar-alt"></i>{{news.Date}}</span>
              <span>By-<a href="javascript:void(0)">{{news.NewsBy}}</a></span>
            </div>
            <!--<div class="item">
              <a href="">Extremely depending he gentleman improving.</a>
              <span><i class="fas fa-calendar-alt"></i> 18 Nov, 2020 -  <a href="javascript:void(0)">Spark</a></span>
            </div>-->
          </div>
        </div>
        <div class="col-lg-2 col-md-6 item">
          <div class="f-item link">
            <h4 class="widget-title">Follow Us</h4>
            <ul>
              <li class="facebook">
                <a href="https://www.facebook.com/people/Gony-Mustafa-Sharif/100069180443898/" target="_blank">
                  <i class="fab fa-facebook-f"></i>
                  <strong>Facebook</strong>
                </a>
              </li>
              <li class="ins">
                <a href="https://www.instagram.com/rehabnova/" target="_blank">
                  <i class="fab fa-instagram"></i>
                  <strong>Instagram</strong>
                </a>
              </li>
              <li class="linkedin">
                <a href="https://www.linkedin.com/in/mustafa-sharif-898967214" target="_blank">
                  <i class="fab fa-linkedin-in"></i>
                  <strong>Linked In</strong>
                </a>
              </li>
              <li class="twitter">
                <a href="https://twitter.com/RehabnovaI" target="_blank">
                  <i class="fab fa-twitter"></i>
                  <strong>Twitter</strong>
                </a>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  </div>
  <!-- Start Footer Bottom -->
  <div class="footer-bottom">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <p>Copyright &copy;  {{copyWrireYear}}. RehabNOVA International</p>
        </div>
        <div class="col-md-6 text-right link">
          <ul>
            <li>
              <a routerLink="./terms">Terms and Conditions</a>
            </li>
            <li>
              <a routerLink="./privacypolicy">Privacy & Policy</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!--End Footer Bottom-->
</footer>
