import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private title: Title, private meta: Meta) { }

  setTitle(title: string) {
    this.title.setTitle(title);
  }

  setDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc })
  }

  setKeywords(keywords: string) {
    this.meta.updateTag({ name: 'keywords', content: keywords })
  }
}
