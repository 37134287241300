import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-theproblems',
  templateUrl: './theproblems.component.html',
  styleUrls: ['./theproblems.component.css']
})
export class TheproblemsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
