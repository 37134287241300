<!-- Start partners Area
    ============================================= -->
<!-- End partner Area -->

<div class="testimonials-area carousel-shadow" style="margin-top:50px;">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <div class="site-heading text-center">
          <h5>Our Partners</h5>
          <h2>
            Thanks to our <br> wonderful partners
          </h2>
          <div class="heading-divider"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-12" style="margin-bottom: 50px;">
        <owl-carousel [options]="{items: 3,dots: true, navigation: false,loop:true,autoplay:true,autoplayTimeout:10000,autoplayHoverPause:true}">
          <div class="item"><img class="ourpartners" src="assets/img/clients/one.jpg" alt="Thumb" style="width: 185px;"></div>
          <div class="item"><img class="ourpartners" src="assets/img/clients/two.png" alt="Thumb" style="width: 185px;"></div>
          <div class="item"><img class="ourpartners" src="assets/img/clients/three.png" alt="Thumb" style="width: 185px;"></div>
          <div class="item"><img class="ourpartners" src="assets/img/clients/four.jpg" alt="Thumb" style="width: 185px;"></div>
          <div class="item"><img class="ourpartners" src="assets/img/clients/five.jpg" alt="Thumb" style="width: 185px;"></div>
          <!--style="height:185px; width:200px;margin-left:90px;"-->
        </owl-carousel>
      </div>
    </div>
  </div>
</div>
