import { Injectable } from '@angular/core';

interface Scripts {
  name: string;
  src: string;
}

export const ScriptStore: Scripts[] = [
  { name: 'jquery1124min', src: '../../assets/js/jquery-1.12.4.min.js' },
  { name: 'poppermin', src: '../../assets/js/popper.min.js' },
  { name: 'bootstrapmin', src: '../../assets/js/bootstrap.min.js'},
  { name: 'equatheightmin', src: '../../assets/js/equal-height.min.js'},
  { name: 'jqueryappear', src: '../../assets/js/jquery.appear.js'},
  { name: 'jqueryeasingmin', src: '../../assets/js/jquery.easing.min.js'},
  { name: 'magnificpopupmin', src: '../../assets/js/jquery.magnific-popup.min.js'},
  { name: 'jqueryniceselectmin', src: '../../assets/js/jquery.nice-select.min.js'},
  { name: 'wowmin', src: '../../assets/js/wow.min.js'},
  { name: 'progressbarmin', src: '../../assets/js/progress-bar.min.js'},
  { name: 'imagesloadmin', src: '../../assets/js/imagesloaded.pkgd.min.js'},
  { name: 'isotopemin', src: '../../assets/js/isotope.pkgd.min.js'},
  { name: 'countto', src: '../../assets/js/count-to.js'},
  { name: 'owlcarouselmin', src: '../../assets/js/owl.carousel.min.js'},
  { name: 'ytplayer', src: '../../assets/js/YTPlayer.min.js'},
  { name: 'bootnav', src: '../../assets/js/bootsnav.js'},
  { name: 'modernizr', src: '../../assets/js/modernizr.custom.13711.js'},
  { name: 'html5shiv', src: '../../assets/js/html5/html5shiv.min.js'},
  { name: 'respondmin', src: '../../assets/js/html5/respond.min.js'},
  { name: 'mainjs', src: '../../assets/js/main.js'},
  { name: 'videogallery', src: '../../assets/js/videogallery.js'}

];

declare var document: any;

@Injectable()
export class ScriptLoaderService {

  private scripts: any = {};

  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      if (!this.scripts[name].loaded) {
        //load script
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {  //IE
            script.onreadystatechange = () => {
                if (script.readyState === "loaded" || script.readyState === "complete") {
                    script.onreadystatechange = null;
                    this.scripts[name].loaded = true;
                    resolve({script: name, loaded: true, status: 'Loaded'});
                }
            };
        } else {  //Others
            script.onload = () => {
                this.scripts[name].loaded = true;
                resolve({script: name, loaded: true, status: 'Loaded'});
            };
        }
        script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
        document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      }
    });
  }

}