export class ConfigurationHelper {
  static Configuration = {
    USER_PROFILE: "RehabnovaUserProfile",
    STATUS_TYPE: { "Error": 0, "Success": 1, "Fail": 2, "Exception": 3, "NotFound": 4 },

    ImageGallery: [
      { Title: "Architecture", InnerTitle: "Health Overal", ImapgePath: "assets/img/gallery/RN-1.jpg" },
      { Title: "Architecture", InnerTitle: "Dining", ImapgePath: "assets/img/gallery/RN-2.jpg" },

      { Title: "Architecture", InnerTitle: "Dining Exterior", ImapgePath: "assets/img/gallery/RN-Dining-Exterior.JPG" },
      { Title: "Architecture", InnerTitle: "Health Overal", ImapgePath: "assets/img/gallery/RN-Health-Overal.JPG" },
      { Title: "Architecture", InnerTitle: "House Exterior", ImapgePath: "assets/img/gallery/RN-House-Exterior.jpg" },

      { Title: "Architecture", InnerTitle: "", ImapgePath: "assets/img/gallery/FR1.jpg" },
      { Title: "Architecture", InnerTitle: "", ImapgePath: "assets/img/gallery/FR2.jpg" },
      { Title: "Architecture", InnerTitle: "", ImapgePath: "assets/img/gallery/FR3.jpg" },
      { Title: "Architecture", InnerTitle: "", ImapgePath: "assets/img/gallery/FR4.jpg" },

      { Title: "Opening", InnerTitle: "Class Room", ImapgePath: "assets/img/gallery/RN-3.jpg" },
      { Title: "", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-4.jpg" },
      { Title: "Medical", InnerTitle: "Aid", ImapgePath: "assets/img/gallery/RN-5.jpg" },
      { Title: "", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-6.jpg" },
      { Title: "", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-7.jpg" },
      { Title: "", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-1-45.JPG" },

      { Title: "Architecture", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-28.JPG" },
      { Title: "Architecture", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-29.JPG" },
      { Title: "Architecture", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-30.JPG" },
      { Title: "Architecture", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-31.JPG" },
      { Title: "Architecture", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-32.JPG" },
      { Title: "Architecture", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-33.JPG" },
      { Title: "Architecture", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-34.JPG" },

      { Title: "Medical", InnerTitle: "Aid", ImapgePath: "assets/img/gallery/RN-8.jpg" },
      { Title: "Medical", InnerTitle: "Aid", ImapgePath: "assets/img/gallery/RN-9.jpeg" },
      { Title: "Medical", InnerTitle: "Aid", ImapgePath: "assets/img/gallery/RN-10.jpg" },
      { Title: "Medical", InnerTitle: "Aid", ImapgePath: "assets/img/gallery/RN-11.jpg" },
      { Title: "Medical", InnerTitle: "Aid", ImapgePath: "assets/img/gallery/RN-MS-2.jpg" },
      { Title: "Kokoland", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-12.JPG" },
      { Title: "Kokoland", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-13.JPG" },
      { Title: "Kokoland", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-14.JPG" },
      { Title: "Kokoland", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-15.JPG" },
      { Title: "Kokoland", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-16.JPG" },
      { Title: "Kokoland", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-17.JPG" },
      { Title: "Kokoland", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-18.JPG" },
      { Title: "Kokoland", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-19.JPG" },
      { Title: "Kokoland", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-20.JPG" },
      { Title: "Kokoland", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-21.JPG" },
      { Title: "Kokoland", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-22.JPG" },
      { Title: "Kokoland", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-23.JPG" },
      { Title: "Kokoland", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-24.JPG" },
      { Title: "Kokoland", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-25.JPG" },
      { Title: "Kokoland", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-26.JPG" },
      { Title: "Kokoland", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-27.JPG" },


      { Title: "Kokoland", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-35.jpeg" },
      { Title: "Kokoland", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-36.jpeg" },
      { Title: "Kokoland", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-37.jpeg" },
      { Title: "Kokoland", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-38.jpeg" },
      { Title: "Kokoland", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-39.jpeg" },
      { Title: "Kokoland", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-40.jpeg" },
      { Title: "Kokoland", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-41.jpeg" },
      { Title: "Kokoland", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-42.jpeg" },
      { Title: "Kokoland", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-43.jpeg" },

      { Title: "", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-46.png" },
      { Title: "", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-47.png" },
      { Title: "", InnerTitle: "", ImapgePath: "assets/img/gallery/RN-48.png" },


      //{ Title: "", InnerTitle: "", ImapgePath: "" },
    ],

    //VideoGallery: [
    //  //{ Title: "Introduction", InnerTitle: "Experiance", Thumbnail: "assets/img/gallery/video/thumbnail/01.jpeg", VideoPath: "https://drive.google.com/file/d/1uXCcAnx4fdV5yadLv2De32n_XRWUIZqw/preview" },
    //  { Title: "Introduction", InnerTitle: "Dr. Mustafa Sharif", Thumbnail: "assets/img/gallery/video/thumbnail/02.jpeg", VideoPath: "https://drive.google.com/file/d/1McSQV2fGuBw5x3_s7-OmaGFnItqAoeZ6/preview" },
    //  { Title: "", InnerTitle: "", Thumbnail: "assets/img/gallery/video/thumbnail/03.jpeg", VideoPath: "https://drive.google.com/file/d/18ZxuSzcbQXR7x3HWROrYEWnBR_L8HNDL/preview" },
    //  { Title: "", InnerTitle: "", Thumbnail: "assets/img/gallery/video/thumbnail/04.jpeg", VideoPath: "https://drive.google.com/file/d/1uM6dt8NFzfmFcyE01bcZ2UEfV7aWr57n/preview" },
    //  { Title: "", InnerTitle: "", Thumbnail: "assets/img/gallery/video/thumbnail/05.jpeg", VideoPath: "https://drive.google.com/file/d/1ekRYI9sTVvDDGNd38jzanYT_AFkY8u-l/preview" },
    //  { Title: "", InnerTitle: "", Thumbnail: "assets/img/gallery/video/thumbnail/06.jpeg", VideoPath: "https://drive.google.com/file/d/1P0rEjPOob-IzEytf2k-Z9rZ8ymDbe4QQ/preview" },
    //  { Title: "", InnerTitle: "", Thumbnail: "assets/img/gallery/video/thumbnail/07.jpeg", VideoPath: "https://drive.google.com/file/d/1YbJZvoX7XMtdZ-Y4pKD6CTC8xSbaZBar/preview" },
    //  { Title: "", InnerTitle: "", Thumbnail: "assets/img/gallery/video/thumbnail/08.jpeg", VideoPath: "https://drive.google.com/file/d/19enTwsGo4mCudgRIb_WNs7-Xg9pi_gt7/preview" },
    //  { Title: "", InnerTitle: "", Thumbnail: "assets/img/gallery/video/thumbnail/09.jpeg", VideoPath: "https://drive.google.com/file/d/1dibAMF4vjDRh9_sYWZD8v813_O_mW4Qs/preview" },
    //  { Title: "", InnerTitle: "", Thumbnail: "assets/img/gallery/video/thumbnail/10.jpeg", VideoPath: "https://drive.google.com/file/d/1RexuF2Na8qujixT0ydKc0oUIHaw1rBbG/preview" },
    //  { Title: "", InnerTitle: "", Thumbnail: "assets/img/gallery/video/thumbnail/11.jpeg", VideoPath: "https://drive.google.com/file/d/1il29ph2QDMLll8mcFyRXqrKuktNapW-U/preview" },
    //  { Title: "", InnerTitle: "", Thumbnail: "assets/img/gallery/video/thumbnail/12.jpeg", VideoPath: "https://drive.google.com/file/d/16rQc7zP9ZikF7ghjqa9irpMGjRvL9ZA-/preview" },
    //  { Title: "", InnerTitle: "", Thumbnail: "assets/img/gallery/video/thumbnail/13.jpeg", VideoPath: "https://drive.google.com/file/d/1PBxpiLP65riE7coDWTkVEcxJSctpjG2K/preview" },
    //  { Title: "", InnerTitle: "", Thumbnail: "assets/img/gallery/video/thumbnail/14.jpeg", VideoPath: "https://drive.google.com/file/d/1g1i9ONgSgwejfqbr6yLCOLSfshVTKzzE/preview" },
    //  { Title: "", InnerTitle: "", Thumbnail: "assets/img/gallery/video/thumbnail/16.jpeg", VideoPath: "https://drive.google.com/file/d/0BybIFzY3lsPMcGRncjR3c05kcWs/preview" },
    //  { Title: "Medial", InnerTitle: "Sipment", Thumbnail: "assets/img/gallery/video/thumbnail/RN-MS-1.jpg", VideoPath: "https://drive.google.com/file/d/1xSRprIF3iHjpU3TYFx-9RTOxhKtVxtjv/preview" },
    //  { Title: "Medical", InnerTitle: "Shipment", Thumbnail: "assets/img/gallery/video/thumbnail/RN-MS-2.jpg", VideoPath: "https://drive.google.com/file/d/1XW0fZU4RoJlprsjE88OZ2sj0k_S-3_C1/preview" },
    //],
    VideoGallery: [
      { Title: "Introduction", InnerTitle: "Experiance", Thumbnail: "https://i1.ytimg.com/vi/g1mRZWn_TT0/mqdefault.jpg", VideoPath: "https://www.youtube.com/watch?v=g1mRZWn_TT0" },
      { Title: "Introduction", InnerTitle: "Dr. Mustafa Sharif", Thumbnail: "https://i1.ytimg.com/vi/gVaoTsWJFds/mqdefault.jpg", VideoPath: "https://www.youtube.com/watch?v=gVaoTsWJFds" },
      { Title: "", InnerTitle: "", Thumbnail: "https://i1.ytimg.com/vi/zHMP5wH-t-4/mqdefault.jpg", VideoPath: "https://www.youtube.com/watch?v=zHMP5wH-t-4" },
      { Title: "", InnerTitle: "", Thumbnail: "https://i1.ytimg.com/vi/u0Zm3PdGrfU/mqdefault.jpg", VideoPath: "https://www.youtube.com/watch?v=u0Zm3PdGrfU" },
      { Title: "", InnerTitle: "", Thumbnail: "https://i1.ytimg.com/vi/jX0a8oFwnJ8/mqdefault.jpg", VideoPath: "https://www.youtube.com/watch?v=jX0a8oFwnJ8" },
      { Title: "", InnerTitle: "", Thumbnail: "https://i1.ytimg.com/vi/DqDe-R9-8pg/mqdefault.jpg", VideoPath: "https://www.youtube.com/watch?v=DqDe-R9-8pg" },
      { Title: "", InnerTitle: "", Thumbnail: "https://i1.ytimg.com/vi/IvqD_merZDg/mqdefault.jpg", VideoPath: "https://www.youtube.com/watch?v=IvqD_merZDg" },
      { Title: "", InnerTitle: "", Thumbnail: "https://i1.ytimg.com/vi/AJ60ok4u4e0/mqdefault.jpg", VideoPath: "https://www.youtube.com/watch?v=AJ60ok4u4e0" },
      { Title: "", InnerTitle: "", Thumbnail: "https://i1.ytimg.com/vi/g0KXep5QK3g/mqdefault.jpg", VideoPath: "https://www.youtube.com/watch?v=g0KXep5QK3g" },
      { Title: "", InnerTitle: "", Thumbnail: "https://i1.ytimg.com/vi/CAtqZ4IF868/mqdefault.jpg", VideoPath: "https://www.youtube.com/watch?v=CAtqZ4IF868" },
      { Title: "", InnerTitle: "", Thumbnail: "https://i1.ytimg.com/vi/3EFXCuwpGYE/mqdefault.jpg", VideoPath: "https://www.youtube.com/watch?v=3EFXCuwpGYE" },
      { Title: "", InnerTitle: "", Thumbnail: "https://i1.ytimg.com/vi/m1R84aKSLdI/mqdefault.jpg", VideoPath: "https://www.youtube.com/watch?v=m1R84aKSLdI" },
      { Title: "", InnerTitle: "", Thumbnail: "https://i1.ytimg.com/vi/snDM9U4ADlI/mqdefault.jpg", VideoPath: "https://www.youtube.com/watch?v=snDM9U4ADlI" },
      { Title: "", InnerTitle: "", Thumbnail: "https://i1.ytimg.com/vi/8072W8b-DrU/mqdefault.jpg", VideoPath: "https://www.youtube.com/watch?v=8072W8b-DrU" },
      { Title: "", InnerTitle: "", Thumbnail: "https://i1.ytimg.com/vi/SgtWRBq9nyo/mqdefault.jpg", VideoPath: "https://www.youtube.com/watch?v=SgtWRBq9nyo" },
      { Title: "Medial", InnerTitle: "Sipment", Thumbnail: "https://i1.ytimg.com/vi/tJprrUjMhNU/mqdefault.jpg", VideoPath: "https://www.youtube.com/watch?v=tJprrUjMhNU" },
      { Title: "Medical", InnerTitle: "Shipment", Thumbnail: "https://i1.ytimg.com/vi/FOPIL8hWGYo/mqdefault.jpg", VideoPath: "https://www.youtube.com/watch?v=FOPIL8hWGYo" },
    ],

    NewEvents: [
      {
        Id: 1,
        Date: "02-Jul-2020",
        ImagePath: "assets/img/blog/RN-Booth-3.jpg",
        VideoPath: "",
        VideoThumbnailPath: "",
        Title: "Rehabnova Featured in Medical Bridges Annual Report",
        NewsBy: "Dr. Mustafa Sharif",
        NewsEvent: "Medical Bridges' strategic partnership with <b>Texas A&M</b> student-run organization, BUILD, has offered rural communities with sturdy health care infrastructure to care for the impoverished worldwide. BUILD was founded to commemorate the twelve students who lost their lives after the collapse of the bonfire in 1999 during an annual tradition of unity. Between 2014 to 2016, hundreds of students reunit-ed to honor those who lost their lives by giving life-saving resources to those in need. Every summer, kindhearted Aggies gather on campus to build these intricate Texas Aggies Medical Clinics in the Texas heat. Together they cut plywood, paint. and rebuild a tradition of unity to do good.<br />" +
          "This year, health heroes in Honduras, Sudan, and Nigeria received mobile clinics referred to as TAMCs. Measuring 40 ft, each medical unit holds the necessary material to provide healthcare in any area of the world. Inside the beautifully designed TAMC, there are two patient rooms, a counter with drawers and a sink, a generator, and an A/C unit. The TAMC is built for harsh conditions; whether it's sent to Haiti in the hot Carribean or Armenia near the freezing Caucasus moun-tains, the built-in insulation provides a comfortable space for both the patient and healthcare provider.<br />" +
          "<b>SERENDIPITIOUS SUDAN</b><br />" +
          "Ten years ago, former Aggie alumni, <b><u>Dr.Mustafa Sharif</u></b> and <b><u>Sofia Sharif</u></b>, had a vision and heart to help their hometown in Sudan.While attaining his Ph.D in Agriculture Development at the Texas A& M Uni - versity, Mustafa spent countless hours developing RehabNOVA with the sole purpose to build a sustainable infrastruture to support his community back home.Finally in 2020, Mustafa called Medical Bridges to inquire about sending medical supplies to Sudan.Gidget Swift, Medical Bridges Global Health Manager at the time, was under the impression he was part of BUILD team, but Dr.Sharif had not heard of the organization.Once Mustafa and Sofia learned more about the mobile clinics they were able to send two TAMC's to Sudan were they are now providing needed health care. All this made possible thanks to the spirit of unity from their alma mater, BUILD and Medical Bridges.<br />",
        TitleEllipse: "",
        NewsEventEllipse: "",
        Images: [],
        Videos: ["https://www.youtube.com/embed/g1mRZWn_TT0"],//,"https://www.youtube.com/embed/tJprrUjMhNU"
        Tags: ["News", "Medical Bridge"],
        Priority: 1,
      },
      {
        Id: 2,
        Date: "08-Apr-2020",
        ImagePath: "assets/img/blog/RN-Booth-1.jpg",
        VideoPath: "",
        VideoThumbnailPath: "",
        Title: "RehabNova Booth - RehabNova's Sustainable Development Model presented at the 18th Annual Municipal Green Building Conference & Expo - Los Angeles California. ",
        NewsBy: "Dr. Mustafa Sharif",
        NewsEvent: "The 18th Annual Municipal Green Building Conference & Expo (MGBCE) was about Mitigation. Adaptation. and Resilience. MGBE, since its inception in 2001, has grown to become the longest-running and largest gathering of leading sustainability and green building advocates within both the public and private sectors in Southern California. The conference and the trade show, which are attended by over 600 persons annually, provides cutting-edge thought leadership, education, and networking opportunities to inform local government agencies, building industry professionals, and the general public about the principles, practices, and products associated with green building.",
        TitleEllipse: "",
        NewsEventEllipse: "",
        Images: ["assets/img/blog/RN-Booth-1.jpg", "assets/img/blog/RN-Booth-2.jpg", "assets/img/blog/RN-Booth-3.jpg"],
        Videos: [],
        Tags: ["RehabNova Booth", "Sustainable Development Model"],
        Priority: 2,
      },
      {
        Id: 3,
        Date: "27-Sep-2020",
        ImagePath: "assets/img/blog/RN-Texas-Build-1.jpg",
        VideoPath: "",
        VideoThumbnailPath: "",
        Title: "Texas A&M BUILD Meeting, Gave presentation to Build Group about RehabNova's program plans and development model to use the TAMCs to provide medical services to those in need in Birka Sudan.",
        NewsBy: "Dr. Mustafa Sharif",
        NewsEvent: "",
        TitleEllipse: "",
        NewsEventEllipse: "",
        Images: [],
        Videos: [],
        Tags: ["News", "Development"],
        Priority: 3,
      },
      {
        Id: 4,
        Date: "04-Dec-2020",
        ImagePath: "assets/img/blog/RN-health-1.jpg",
        VideoPath: "",
        VideoThumbnailPath: "",
        Title: "Shipment of two Medical Clinics from America to Birka Town, Sudan.",
        NewsBy: "Dr. Mustafa Sharif",
        NewsEvent: "RehabNOVA’s proposed program involves four main components;  Health & Health Education (build health clinics), Agriculture & Agri-Business Innovation & Development, Vocational Training, and Revenue Generation. The program aims at providing sustainable solutions to rural communities in Sub-Saharan Africa to empower vulnerable citizens with skills necessary for improving their standard of living and promoting independence.",
        TitleEllipse: "",
        NewsEventEllipse: "",
        Images: [],
        Videos: ["https://www.youtube.com/embed/jSeoSxOXIb4","https://www.youtube.com/embed/tJprrUjMhNU"],//"https://drive.google.com/file/d/1YNLYsvvbb3U8hy2KnBCpuAULpCCF4ny6/preview"
        Tags: ["News", "Medical Shipment"],
        Priority: 4,
      },
      {
        Id: 5,
        Date: "20-May-2019",
        ImagePath: "assets/img/blog/book-signing.jpg",
        VideoPath: "",
        VideoThumbnailPath: "",
        Title: "Signing My Book: iVillager",
        NewsBy: "Dr. Mustafa Sharif",
        NewsEvent: "My lifetime Journey from Kokoland to America. All proceeds from the book sale will go to fund RehabNOVA Projects.",
        TitleEllipse: "",
        NewsEventEllipse: "",
        Images: [],
        Videos: [],
        Tags: ["Event", "My Book"],
        Priority: 5,
      },
      {
        Id: 6,
        Date: "25-Nov-2022",
        ImagePath: "assets/img/blog/birka_clinic7.jpg",
        VideoPath: "",
        VideoThumbnailPath: "",
        Title: "Birka community in Sudan celebrates the delivery of two mobile clinics and medical equipment",
        NewsBy: "Dr. Mustafa Sharif",
        NewsEvent: "The collaborative efforts of Texas A&M University College of Architecture, Texas A&M Students Organization - BUILD, The Norman Borlaug Institute, The Bush School of Government, Medical Bridges, and our organization; RehabNOVA International, have culminated in the two mobile clinics and the medical equipment that we sent to Sudan exactly two years ago, finally reaching their destination and soon will be put to use serving the community of Birka, Kordofan, Sudan. Many thanks to all friends who were generous enough to support us throughout this journey. Attached are videos and images of the Birka community celebrating the delivery of the clinics.",
        TitleEllipse: "",
        NewsEventEllipse: "",
        Images: ["assets/img/blog/birka_clinic1.jpg", "assets/img/blog/birka_clinic2.jpg", "assets/img/blog/birka_clinic3.jpg", "assets/img/blog/birka_clinic4.jpg", "assets/img/blog/birka_clinic5.jpg", "assets/img/blog/birka_clinic6.jpg", "assets/img/blog/birka_clinic7.jpg", "assets/img/blog/birka_clinic8.jpg", "assets/img/blog/birka_clinic9.jpg", "assets/img/blog/birka_clinic10.jpg", "assets/img/blog/birka_clinic11.jpg", "assets/img/blog/birka_clinic12.jpg", "assets/img/blog/birka_clinic13.jpg", "assets/img/blog/birka_clinic14.jpg", "assets/img/blog/birka_clinic15.jpg", "assets/img/blog/birka_clinic16.jpg", "assets/img/blog/birka_clinic17.jpg", "assets/img/blog/birka_clinic18.jpg", "assets/img/blog/birka_clinic19.jpg", "assets/img/blog/birka_clinic20.jpg"],
        Videos: ["https://www.youtube.com/embed/2ciaYRwHiEQ",
          "https://www.youtube.com/embed/5yi1zzTztgA",
          "https://www.youtube.com/embed/htMMHWEaAeQ",
          "https://www.youtube.com/embed/k9i-GGayx2g",
          "https://www.youtube.com/embed/ldxpisaIzCQ",
          "https://www.youtube.com/embed/jHh2k2cVS6w",
          "https://www.youtube.com/embed/MG-66zI2Gs8",
          "https://www.youtube.com/embed/DC8PQGtFxRw"],
        Tags: ["Event", "Mobile Clinics and Medical Equipment"],
        Priority: 6,
      },
      {
        Id: 7,
        Date: "21-Jan-2023",
        ImagePath: "assets/img/blog/birka_clinic_inaugration11.jpg",
        VideoPath: "",
        VideoThumbnailPath: "",
        Title: "Inauguration of Medical Clinics in Birka, Sudan",
        NewsBy: "Dr. Mustafa Sharif",
        NewsEvent: "Dear friends,<br/>RehabNOVA International<br/><a href='www.rehabnova.org'>www.rehabnova.org</a><br/>Birka, North Kordofan, Sudan.<br/>We are delighted to announce that we finally inaugurated the two clinics that we’ve shipped from America to Sudan.Many thanks to our partners; The Governor of North Kordofan State and his Ministers of Health, Agriculture, and Education, who were all present that day.Also thanks to the representatives of FAO, Plan Sudan, The Kuwaiti Hospital, andthe United Kingdom- based Kordofan Development Foundation.<br/>Our ultimate goal is to build a comprehensive and a self - sustaining development campus in Birka to cater for health, agriculture and agribusiness, education and vocational training, and entrepreneurship.<br/>We plan to duplicate the model in other areas, in or outside of Sudan.",
        TitleEllipse: "",
        NewsEventEllipse: "",
        Images: ["assets/img/blog/birka_clinic_inaugration1.jpg", "assets/img/blog/birka_clinic_inaugration2.jpg", "assets/img/blog/birka_clinic_inaugration3.jpg", "assets/img/blog/birka_clinic_inaugration4.jpg", "assets/img/blog/birka_clinic_inaugration5.jpg", "assets/img/blog/birka_clinic_inaugration6.jpg", "assets/img/blog/birka_clinic_inaugration7.jpg", "assets/img/blog/birka_clinic_inaugration8.jpg", "assets/img/blog/birka_clinic_inaugration9.jpg", "assets/img/blog/birka_clinic_inaugration10.jpg", "assets/img/blog/birka_clinic_inaugration11.jpg", "assets/img/blog/birka_clinic_inaugration12.jpg", "assets/img/blog/birka_clinic_inaugration13.jpg", "assets/img/blog/birka_clinic_inaugration14.jpg", "assets/img/blog/birka_clinic_inaugration15.jpg", "assets/img/blog/birka_clinic_inaugration16.jpg", "assets/img/blog/birka_clinic_inaugration17.jpg", "assets/img/blog/birka_clinic_inaugration18.jpg", "assets/img/blog/birka_clinic_inaugration19.jpg", "assets/img/blog/birka_clinic_inaugration20.jpg", "assets/img/blog/birka_clinic_inaugration21.jpg", "assets/img/blog/birka_clinic_inaugration22.jpg"],
        Videos: ["https://www.youtube.com/embed/CtK6pEok_Q0",
          "https://www.youtube.com/embed/LHswB8p2tiI"],
        Tags: ["Event", "Medical Clinic Inaugration"],
        Priority: 7,
      },
      //{Id:, Date: "", ImagePaths: [], Title: "", NewsBy: "", News: "" , Images: [],Videos: [],},
    ]


  }
}
