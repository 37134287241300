import { Component, OnInit } from '@angular/core';
declare const $: any;

@Component({
  selector: 'app-ourteam',
  templateUrl: './ourteam.component.html',
  styleUrls: ['./ourteam.component.css']
})
export class OurteamComponent implements OnInit {

  constructor() { }
  sliderOptions = {
    items: 2,
    dots: true,
    nav: true,
    navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
    loop: true,
    autoplay: true,
    autoplayTimeout: 10000,
    autoplayHoverPause: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1
      },
      700: {
        items: 1
      },
      1000: {
        items: 2,
      }
    },
  }

  sliderOptions2 = {
    items: 1,
    dots: false,
    nav: false,
    //navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
    loop: false,
    autoplay: false,
    autoplayTimeout: 0,
    autoplayHoverPause: false,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1
      },
      700: {
        items: 1
      },
      1000: {
        items: 1,
      }
    },
  }

  ngOnInit(): void {
    
  }

}
