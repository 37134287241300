<div class="testimonials-area carousel-shadow" style="margin-top:50px;">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <div class="site-heading text-center">
          <h5>Testimonials</h5>
          <h2>
            What people say <br> About RehabNOVA
          </h2>
          <div class="heading-divider"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <owl-carousel [options]="sliderOptions" [carouselClasses]="['owl-theme', 'row', 'sliding']">
          <div class="item">
            <div class="info maxminheight">
              <div class="top-info">
                <i class="flaticon-left-quote-1"></i>
                <div class="provider">
                  <h4>Dr. Ahmed Hashim</h4>
                  <small></small>
                </div>
              </div>
              <p>
                RehabNOVA International demonstrated an amazing ability to help build a brighter and better future for villagers in remote parts of Africa. Their policy pays special attention to the root causes of challenges facing poor Africans by addressing them in significant and measurable ways. The current pandemic showed that people's health is linked across the globe, and RehabNOVA got the capability to influence and improve the lives of villagers in Sudan.
              </p>
            </div>
          </div>
          <div class="item">
            <div class="info maxminheight">
              <div class="top-info">
                <i class="flaticon-left-quote-1"></i>
                <div class="provider">
                  <h4>Sanjay Poojary</h4><small>CEO saya.life</small>
                </div>
              </div>
              <!--<p>
        Mustafa is a visionary leader and a charismatic storyteller. My interaction with Mustafa was at the Los Angeles Cleantech Incubator where he was evangelizing his vision for his home country “Sudan”.  Mustafa has a simple look to life which he aptly articulated in his biography “iVillager: My Lifetime Journey from Kokoland to America” Mustafa holds a doctoral degree in urban planning and regional planning from Texas A&M University.
        His mission in life is a noble one!!! Give back to the people of Sudan a dream of sustainable living.
        When I heard about his vision via his company RehabNOVA international I was blown away.
        He has shown amazing leadership to take a concept and bring it to life for people in I wish him all the success and urge anyone looking to support Sustainability projects to invest in his endeavor!!
      </p>-->
              <p>
                Mustafa is a visionary leader and a charismatic storyteller. My interaction with Mustafa was at the Los Angeles Cleantech Incubator where he was evangelizing his vision for his home country “Sudan”.  Mustafa has a simple look to life which he aptly articulated in his biography “iVillager: My Lifetime Journey from Kokoland to America” Mustafa holds a doctoral degree in urban planning and regional planning from Texas A&M University. His mission in life is a noble one!!! Give back to the people of Sudan a dream of sustainable living.
              </p>
            </div>
          </div>

          <div class="item">
            <div class="info maxminheight">
              <div class="top-info">
                <i class="flaticon-left-quote-1"></i>
                <div class="provider">
                  <h4>Alex Santos</h4><small>Texas A&M University College of Architecture Class of 2016 </small>
                </div>
              </div>
              <p>
                Working with the RehabNova project was a very fulfilling experience that had great challenges trying to solve one of the hardest questions with finding sustainable solutions towards prosperous and healthy communities. I love the project and support the people who run it and am excited to work with them again, soon. With love, passion and hard work, RehabNova will make great changes in the world and I hope I can continue to be a part of the success stories they will create in communities of need.
              </p>
            </div>
          </div>

          <div class="item">
            <div class="info maxminheight">
              <div class="top-info">
                <i class="flaticon-left-quote-1"></i>
                <div class="provider">
                  <h4>Trevonna Hayle</h4><small>Global Health Manager, Medical Bridges, Inc.</small>
                </div>
              </div>
              <p>
                I was happy to support RehabNOVA in their receipt of two medical clinics for Sudan. RehabNOVA has made a great impact in its community by building a school, and now by establishing two much needed clinics. I am grateful for the many lives that RehabNOVA continues to touch. This organization has a huge heart for community and look forward to seeing it grow for years to come.
              </p>
            </div>
          </div>
        </owl-carousel>
      </div>
    </div>
  </div>
</div>

