import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { NewsdetailsComponent } from './pages/news/newsdetails/newsdetails.component';
import { NotFoundComponent } from './pages/miscellaneous/not-found/not-found.component';
import { HomeComponent } from './pages/home/home.component';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
  },
  //{ path: '**', redirectTo: 'home' },
  { path: '**', component: HomeComponent },
];

@NgModule({
  //declarations: [AppComponent],
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
