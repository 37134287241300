import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PagesService } from './pages/pages.service';
import { ExceptionhandlerService } from './services/exceptionhandler.service';
import { Subscribe } from './models/subscribe';
import { BaseResponseModel } from './models/base-response-model';
import { EnumModel } from './pages/enums/status';
//import { ActivatedRoute } from '@angular/router';
import { ConfigurationHelper } from './helpers/configuration-helper';
import { NewsEvent } from './models/news-event';
declare const $: any;
import { filter, map, mergeMap } from 'rxjs/operators';
import { SeoService } from './services/seo.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'RehabNOVAUI';
  isSubmitted: boolean = false;
  subscribeModel: Subscribe = new Subscribe();
  isFormSubmitted: boolean = false;
  public isShowDonateButton: boolean = true;
  newsList: NewsEvent[];
  topNews: NewsEvent[];
  isShowFooterNews: boolean = true;
  copyWrireYear: string = "";

  navbarOpen = false;

  constructor(private pagesService: PagesService, private exceptionHandler: ExceptionhandlerService,
    public router: Router, private toastr: ToastrService, private pageService: PagesService, private activatedRoute: ActivatedRoute, private seoService: SeoService) {
    this.newsList = [];
    this.topNews = [];

  }

  ngOnInit(): void {
    //if (environment.production) {
    //  if (location.protocol === 'http:') {
    //    window.location.href = location.href.replace('http', 'https');
    //  }
    //}
    this.subscribeMetaEvent();
    var that = this;
    $('.navbar-nav>li>a').on('click', function () {
      $('.navbar-collapse').collapse('hide');
      that.navbarOpen = false;
    });
    this.copyWrireYear = new Date().getFullYear().toString();
    this.getTopNews();
  }

  toggleNavbar() {
    this.navbarOpen = true;
  }

  closeNavBar() {
    this.navbarOpen = false;
    $('.navbar-collapse').collapse('hide');
  }


  subscribe(email: any) {
    if (!email.valid) {
      this.isFormSubmitted = true;
      return;
    }
    else {
      this.isFormSubmitted = false;
      $(".se-pre-con").fadeIn("slow");
      this.pagesService.subscription(this.subscribeModel.Email).subscribe((result: BaseResponseModel | any) => {
        $(".se-pre-con").fadeOut("slow");
        if (result.StatusCode == EnumModel.Enum.STATUS_TYPE.Success) {
          this.toastr.success(result.Message, 'Success');
          this.subscribeModel = new Subscribe();
          //this.router.navigate(['contactus']);
        }
        else {
          this.toastr.error(result.Message);
        }
      }, error => {
        $(".se-pre-con").fadeOut("slow");
        this.exceptionHandler.handleExcption(error);
      });
      email.reset();
    }
  }

  getTopNews() {
    this.newsList = ConfigurationHelper.Configuration.NewEvents.sort((a, b) => a.Priority - b.Priority).slice(0, 2);
    for (var i = 0; i < this.newsList.length; i++) {
      if (this.newsList[i].NewsEvent != "undefined")
        this.newsList[i].NewsEventEllipse = this.pageService.transform(this.newsList[i].NewsEvent, 100);
      if (this.newsList[i].Title != "undefined")
        this.newsList[i].TitleEllipse = this.pageService.transform(this.newsList[i].Title, 50);
    }
  }

  redirectTo(id: any) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/news/newsdetails"], { queryParams: { id: +id } }));
  }

  subscribeMetaEvent() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    ).subscribe((event) => {
      this.seoService.setTitle(event['title']);
      this.seoService.setDescription(event['title'] + '. ' + event['description']);
      //this.seoService.setKeywords(event['title'] + '. ' + event['keywords']);
    });
  }

}
