import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.css']
})
export class TestimonialComponent implements OnInit {

  constructor() { }
  sliderOptions = {
    items: 2,
    dots: true,
    navigation: false,
    loop: true,
    autoplay: true,
    autoplayTimeout: 10000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1
      },
      700: {
        items: 1
      },
      1000: {
        items: 2,
      }
    }
  }
  ngOnInit(): void {
  }

}
