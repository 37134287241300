<div class="testimonials-area carousel-shadow">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <div class="site-heading text-center">
          <h5>Our Team</h5>
          <br>
          <div class="heading-divider"></div>
        </div>
      </div>
    </div>
  </div>
  <!--<div class="mission-area half-bg bg-gray">
    <div class="container">
      <div class="row">
        <div class="info">
          <div class="top-info">
            <div class="overlay">
              <img src="assets/img/team/RM-Mustafa-2.jpg" class="rounded" style="width:125px; height:150px;border: 2px solid #000099;" alt="Thumb" />
            </div>
            <div class="">
              <h4 style="font-weight: 800;">Dr. Mustafa Sharif</h4>
              <span style="color: #000099;">President and Chairperson</span>
            </div>
          </div>
          <span>
            Dr. Mustafa Sharif Holds a Doctoral degree in Urban and Regional Scineces from Texas A&M University College of Architecture, College Station, Texas.
          </span><a data-toggle="modal" href="#MustafaSharifModal">...Read More</a>
        </div>
      </div>
    </div>
  </div>-->
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <owl-carousel [options]="sliderOptions2" [carouselClasses]="['owl-theme', 'row', 'sliding']">
          <div class="item">
            <div class="info">
              <div class="top-info">
                <div class="row col-12">
                  <div class="col-md-3">
                    <div class="overlay">
                      <img src="assets/img/team/RM-Mustafa-2.jpg" class="rounded" style="width:185px; height:215px;border: 2px solid #000099;" alt="Thumb" />
                    </div>
                    <div class="provider" style="margin-top: 25px; margin-bottom:15px;">
                      <h4 class="fontsize">Dr. Mustafa Sharif</h4>
                      <span>President and Chairperson</span>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <span>
                      <!-- Dr. Mustafa Sharif Holds a Doctoral degree in Urban and Regional Scineces from Texas A&M University College of Architecture, College Station, Texas.-->
                      Dr. Mustafa Sharif Holds a Doctoral degree in Urban and Regional Scineces from Texas A&M University College of Architecture, College Station, Texas.
                      <span class="ourteamstatic">
                        He also holds a Master’s degree (MBA) in Banking and Finance from Stirling Universiy, Stirling, Great Britian, as well as a Bachelor’s degree in Business Adminstration - Banking from Sudan. He has extensive experience in the fields of urban planning, research, economic development, finance and banking.
                        Dr. Sharif worked as a lecturer and Assistant Professor of Practice of landscape architecture and urban planning for five years at the Texas A&M University College of Architecture.
                        He also worked for several banks; Wells Fargo Bank in Palo Alto, San Francisco Bay Area, California; Citi Bank; Oman International Bank, and the Royal Bank of Scotland in Edinburgh, Scotland - as an economic researcher.
                        Dr. Sharif’s research interests include economic development, sustainability, renewables, and urban planning. His research work has centered on energy sustainability - corporate social responsibility and green practices adopted by cities in Texas.
                        <!--<b>Special Areas of Interest:</b></span>
                                              Sustainability and sustainable development; samrt growth; samrt decline; zoning, land use and comprehensive city planning; urban growth and decline; inner urban revitalization; gentrification; quantitative analysis; international development; housing; inclusionary planning; investment and finance; energy sustainability.-->
                      </span>
                    </span><a data-toggle="modal" href="#MustafaSharifModal">...Read More</a>
                  </div>
                </div>
                <!--<div class="row">
                  <div class="col-md-5">
                    <div class="provider">
                      <h4>Dr. Mustafa Sharif</h4>
                      <span>President and Chairperson</span>
                    </div>
                  </div>
                </div>-->
              </div>
            </div>
          </div>
        </owl-carousel>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <owl-carousel [options]="sliderOptions" [carouselClasses]="['owl-theme', 'row', 'sliding']">
          <!--<div class="item">
            <div class="info">
              <div class="top-info">
                <div class="overlay">
                  <img src="assets/img/team/RM-Mustafa-2.jpg" class="rounded" style="width:125px; height:150px;border: 2px solid #000099;" alt="Thumb" />
                </div>
                <div class="provider">
                  <h4>Dr. Mustafa Sharif</h4>
                  <span>President and Chairperson</span>
                </div>
              </div>
              <span>
                Dr. Mustafa Sharif Holds a Doctoral degree in Urban and Regional Scineces from Texas A&M University College of Architecture, College Station, Texas.
              </span><a data-toggle="modal" href="#MustafaSharifModal">...Read More</a>
            </div>
          </div>-->

          <div class="item">
            <div class="info">
              <div class="top-info">
                <div class="overlay">
                  <img src="assets/img/team/RN-ZSharif.png" class="rounded" style="width:125px; height:150px;border: 2px solid #000099;" alt="Thumb" />
                </div>
                <div class="provider">
                  <h4>Mr. Zuhair M. Sharif <small>(MBA)</small></h4>
                  <span>Vice Chairperson and Board Member</span>
                </div>
              </div>
              <span>
                Zuheir Sharif is at the intersection of business, consulting, and government. In the private sector, Zuheir developed the strategic plan and financial
              </span><a data-toggle="modal" href="#ZuhairSharifModal">...Read More</a>
            </div>
          </div>

          <div class="item">
            <div class="info">
              <div class="top-info">
                <div class="overlay">
                  <img src="assets/img/team/RN-Esther-2.jpg" class="rounded" style="width:125px; height:150px;border: 2px solid #000099;" alt="Thumb" />
                </div>
                <div class="provider">
                  <h4>Mrs. Esther Spraque <small>(MSC)</small></h4>
                  <span>Treasurer and Board Member</span>
                </div>
              </div>
              <span>
                Esther Sprague has been a Sudan activist since 2003 after meeting Mamer Kuer Ajak, one of the Lost Boys of Sudan. She is the Founder and Director
              </span><a data-toggle="modal" href="#EstherSpraqueModal">...Read More</a>
            </div>
          </div>

          <div class="item">
            <div class="info">
              <div class="top-info">
                <div class="overlay">
                  <img src="assets/img/team/RN-AmirIbrahim-1.jpg" class="rounded" style="width:125px; height:150px;border: 2px solid #000099;" alt="Thumb" />
                </div>
                <div class="provider">
                  <h4>Dr. Amir Ibrahim</h4>
                  <span>Special Advisor and Board Member</span>
                </div>
              </div>
              <span>
                Professor Amir M. H. Ibrahim is globally renowned for his leadership in wheat research and development programs. His graduate research program is
              </span><a data-toggle="modal" href="#AmirIbrahimModal">...Read More</a>
            </div>
          </div>

          <div class="item">
            <div class="info">
              <div class="top-info">
                <div class="overlay">
                  <img src="assets/img/team/RN-Todd-1.jpg" class="rounded" style="width:125px; height:150px;border: 2px solid #000099;" alt="Thumb" />
                </div>
                <div class="provider">
                  <h4>Mr. Todd Hitomi</h4>
                  <span>Secretary, Strategic Planner and Board Member</span>
                </div>
                <span>
                  Todd Hitomi is a graduate of the University of California, Berkeley, where he received his degree in the Interdisciplinary Studies Field Department during which <!--he wrote his-->
                </span>
                <a data-toggle="modal" href="#ToddHitomiModal">...Read More</a>
              </div>
            </div>
          </div>

          <div class="item">
            <div class="info">
              <div class="top-info">
                <div class="overlay">
                  <img src="assets/img/team/RM-GMaan-1.jpg" class="rounded" style="width:125px; height:150px;border: 2px solid #000099;" alt="Thumb" />
                </div>
                <div class="provider">
                  <h4>Dr. George Mann</h4>
                  <span>Special Advisor (Architecture and Health Facilities)</span>
                </div>
              </div>
              <span>
                George J. Mann AIA, is a Professor of Architecture and the Ronald L. Skaggs, FAIA Endowed Professor of Health Facilities Design at Texas A&M University.
              </span><a data-toggle="modal" href="#GeorgeMannModal">...Read More</a>
            </div>
          </div>
        </owl-carousel>
      </div>
    </div>
  </div>
</div>

<!-- End our team Area -->
<!--MustafaSharifModal starts-->
<div class="modal fade" id="MustafaSharifModal" tabindex="-1" role="dialog" aria-labelledby="MustafaSharifModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="testimonial-items">
                <div class="item">
                  <div class="info">
                    <div class="top-info">
                      <div class="overlay" >
                        <img src="assets/img/team/RM-Mustafa-2.jpg" class="rounded" style="width:125px; height:150px;border: 2px solid #000099;" alt="Thumb" />
                      </div>
                      <div class="provider" style="margin-top: 25px; margin-bottom:15px;">
                        <h4 class="zero-margin"><b>Dr. Mustafa Sharif</b></h4>
                        <span style="color:#000099;">President and Chairperson</span>
                      </div>
                    </div>
                    <span>
                      Dr. Mustafa Sharif Holds a Doctoral degree in Urban and Regional Scineces from Texas A&M University College of Architecture, College Station, Texas. He also holds a Master’s degree (MBA) in Banking and Finance from Stirling Universiy, Stirling, Great Britian, as well as a Bachelor’s degree in Business Adminstration - Banking from Sudan. He has extensive experience in the fields of urban planning, research, economic development, finance and banking.
                      Dr. Sharif worked as a lecturer and Assistant Professor of Practice of landscape architecture and urban planning for five years at the Texas A&M University College of Architecture. He also worked for several banks; Wells Fargo Bank in Palo Alto, San Francisco Bay Area, California; Citi Bank; Oman International Bank, and the Royal Bank of Scotland in Edinburgh, Scotland - as an economic researcher.
                      Dr. Sharif’s research interests include economic development, sustainability, renewables, and urban planning. His research work has centered on energy sustainability - corporate social responsibility and green practices adopted by cities in Texas.
                      <b>Special Areas of Interest:</b>
                      Sustainability and sustainable development; samrt growth; samrt decline; zoning, land use and comprehensive city planning; urban growth and decline; inner urban revitalization; gentrification; quantitative analysis; international development; housing; inclusionary planning; investment and finance; energy sustainability.
                    </span>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--ZuhairSharifModal starts-->
<div class="modal fade" id="ZuhairSharifModal" tabindex="-1" role="dialog" aria-labelledby="ZuhairSharifModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="testimonial-items">
                <div class="item">
                  <div class="info">
                    <div class="top-info">
                      <div class="overlay">
                        <img src="assets/img/team/RN-ZSharif.png" class="rounded" style="width:125px; height:150px;border: 2px solid #000099;" alt="Thumb" />
                      </div>
                      <div class="provider" style="margin-top: 25px; margin-bottom:15px;">
                        <h4 class="zero-margin"><b>Mr. Zuhair M. Sharif</b>&nbsp;<small>(MBA)</small> </h4>
                        <span style="color:#000099;">Vice Chairperson and Board Member</span>
                      </div>
                    </div>
                    <p>
                      Zuheir Sharif is at the intersection of business, consulting, and government. In the private sector, Zuheir developed the strategic plan and financial modeling for an ESG firm that expands organizations’ capacity to integrate sustainability best practices. From March 2015 through January 2017, he served as a Special Advisor to the Chief of Staff in the Office of the Secretary of Commerce in the Obama Administration. Zuheir holds an MBA from Columbia Business School.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--EstherSpraqueModal starts-->
<div class="modal fade" id="EstherSpraqueModal" tabindex="-1" role="dialog" aria-labelledby="EstherSpraqueModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="testimonial-items">
                <div class="item">
                  <div class="info">
                    <div class="top-info">
                      <div class="overlay">
                        <img src="assets/img/team/RN-Esther-2.jpg" class="rounded" style="width:125px; height:150px;border: 2px solid #000099;" alt="Thumb" />
                      </div>
                      <div class="provider" style="margin-top: 25px; margin-bottom:15px;">
                        <h4 class="zero-margin"><b>Mrs. Esther Spraque</b>&nbsp;<small>(MSC)</small> </h4>
                        <span style="color:#000099;">Treasurer and Board Member</span>
                      </div>
                    </div>
                    <span>
                      Esther Sprague has been a Sudan activist since 2003 after meeting Mamer Kuer Ajak, one of the Lost Boys of Sudan.  She is the Founder and Director of Sudan Unlimited, which seeks to support Sudanese and Southern Sudanese in their efforts to secure and enjoy freedom, equal citizenship and a just peace.  Esther has a degree in business from Boise State University and a master’s degree in International Studies at the University of San Francisco.  She is currently the Budget and Finance Director at the University of San Francisco.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--AmirIbrahimModal starts-->
<div class="modal fade" id="AmirIbrahimModal" tabindex="-1" role="dialog" aria-labelledby="AmirIbrahimModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="testimonial-items">
                <div class="item">
                  <div class="info">
                    <div class="top-info">
                      <div class="overlay">
                        <img src="assets/img/team/RN-AmirIbrahim-1.jpg" class="rounded" style="width:125px; height:150px;border: 2px solid #000099;" alt="Thumb" />
                      </div>
                      <div class="provider" style="margin-top: 25px; margin-bottom:15px;">
                        <h4 class="zero-margin"><b>Dr. Amir Ibrahim</b></h4>
                        <span style="color:#000099;">Special Advisor and Board Member</span>
                      </div>
                    </div>
                    <span>
                      Professor Amir M. H. Ibrahim is globally renowned for his leadership in wheat research and development programs. His graduate research program is producing the wheat breeders for the next generation.  Professor Ibrahim has released and/or co-released 18 winter wheat and three oat cultivars.

                      Professor Ibrahim is also globally recognized in applied research on genetic control of end-use quality and biotic and abiotic stress tolerance in wheat as well as hybrid wheat.

                      In addition to his teaching duties, Professor Ibrahim has served as the adviser or co-adviser of 17 doctorate and 15 master’s students, most of whom hold research and leadership positions in the public and private sectors. He has published 90 referred journal articles, 36 Extension papers, 11 technical reports, 2 book chapters and 87 abstracts and proceedings.

                      Professor Ibrahim earned his bachelor’s degree from the University of Gezira in Sudan, his master’s degree from the American University of Beirut in Lebanon and his doctorate from Colorado State University.

                      Professor Ibrahim is a Crop Science Society of America Fellow and Texas A&M AgriLife Fellow.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--ToddHitomiModal starts-->
<div class="modal fade" id="ToddHitomiModal" tabindex="-1" role="dialog" aria-labelledby="ToddHitomiModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="testimonial-items">
                <div class="item">
                  <div class="info">
                    <div class="top-info">
                      <div class="overlay">
                        <img src="assets/img/team/RN-Todd-1.jpg" class="rounded" style="width:125px; height:150px;border: 2px solid #000099;" alt="Thumb" />
                      </div>
                      <div class="provider" style="margin-top: 25px; margin-bottom:15px;">
                        <h4 class="zero-margin"><b>Mr. Todd Hitomi</b></h4>
                        <span style="color:#000099;">Secretary, Strategic Planner and Board Member</span>
                      </div>
                    </div>
                    <span>
                      Todd Hitomi is a graduate of the University of California, Berkeley, where he received his degree in the Interdisciplinary Studies Field Department during which he wrote his thesis on the Dialectics of Technology and Architecture. Todd is currently part of the Los Angeles Cleantech Incubator helping to manage a portfolio of thirty-two companies developing clean technologies for introduction to the market. Grounded in his studies Todd has a particular interest in helping to shape technology as a equalizer and enabler in social justice. In the past, he had developed an educational outreach program in oral health and hygiene for low-income communities in the Los Angeles area.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--GeorgeMannModal starts-->
<div class="modal fade" id="GeorgeMannModal" tabindex="-1" role="dialog" aria-labelledby="GeorgeMannModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="testimonial-items">
                <div class="item">
                  <div class="info">
                    <div class="top-info">
                      <div class="overlay">
                        <img src="assets/img/team/RM-GMaan-1.jpg" class="rounded" style="width:125px; height:150px;border: 2px solid #000099;" alt="Thumb" />
                      </div>
                      <div class="provider" style="margin-top: 25px; margin-bottom:15px;">
                        <h4 class="zero-margin"><b>Dr. George Mann</b></h4>
                        <span style="color:#000099;">Special Advisor (Architecture and Health Facilities)</span>
                      </div>
                    </div>
                    <span>
                      George J. Mann AIA, is a Professor of Architecture and the Ronald L. Skaggs, FAIA Endowed Professor of Health Facilities Design at Texas A&M University. He has a national and international reputation as a leader in health facilities design with over 52 years of pioneering experience in the field of architecture for health through research, teaching, consulting, and practice.
                      Professor Mann has been involved in over 800 facility projects world-wide with selected healthcare infrastructure projects throughout the United States, the Middle East, Asia, and Central & South America. Dr. Mann has also attracted over $3.5M in sponsored grants and research contracts to Texas A&M University as well as guided the architectural education of over 4000 students, many of whom now lead the architecture for health field across the globe.
                      After graduating from the Columbia University School of Architecture with a Bachelor of Architecture, followed by a Master of Science in Architecture for Health, he was awarded a William Kinne traveling Fellowship to study health facilities in Europe and the Middle East. He apprenticed with I.M. Pei and Associates and Skidmore Owings and Merrill Architects in New York City. He is on the editorial advisory board of Medical Construction and Design.
                      He also is the founder and advisory board Chairman of the RPD (Resource Planning & Development) Group.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
