import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ScriptLoaderService } from '../../services/script-loader.service';
import { PagesService } from '../pages.service';
declare const $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {

  constructor(private pageService: PagesService) {

  }

  ngOnInit(): void {
    $('.carousel').carousel({
      interval: 10000,
      cycle: true,
      pause: "null"
    });

  }

  ngAfterViewInit() {
    //this.ngLoadJquery();
    if (!localStorage.getItem('foo')) {
      localStorage.setItem('foo', 'no reload')
      location.reload()
    } else {
      localStorage.removeItem('foo')
    }
  }

  ngLoadJquery() {
    /* ==================================================
      # Youtube Video Init
   ===============================================*/
    $('.player').mb_YTPlayer();
    /* ==================================================
        # Magnific popup init
     ===============================================*/
    $(".popup-link").magnificPopup({
      type: 'image',
      // other options
    });

    $(".popup-gallery").magnificPopup({
      type: 'image',
      gallery: {
        enabled: true
      },
      // other options
    });

    $(".popup-youtube, .popup-vimeo, .popup-gmaps").magnificPopup({
      type: "iframe",
      mainClass: "mfp-fade",
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false
    });
  }
}



