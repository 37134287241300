import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.css']
})
export class PartnersComponent implements OnInit {
  constructor() { }
  //sliderOptions = {
  //  items: 3,
  //  dots: true,
  //  navigation: false,
  //  loop: true,
  //  autoplay: true,
  //  autoplayTimeout: 10000,
  //  autoplayHoverPause: true,
  //  responsive: {
  //    0: {
  //      items: 1
  //    },
  //    700: {
  //      items: 1
  //    },
  //    1000: {
  //      items: 2,
  //    }
  //  }
  //}

  sliderOptions = {
    items: 3,
    dots: false,
    navigation: false,
    loop: true,
    autoplay: true,
    autoplayTimeout: 10000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1
      },
      700: {
        items: 1
      },
      1000: {
        items: 2,
      }
    }
  }
  ngOnInit(): void {
    //$(document).ready(function () {

    //  $("#owl-demo").owlCarousel({

    //    autoPlay: 3000, //Set AutoPlay to 3 seconds

    //    items: 4,
    //    itemsDesktop: [1199, 3],
    //    itemsDesktopSmall: [979, 3]

    //  });

    //});
  }

}
