import { Component, OnInit } from '@angular/core';
declare const $: any;


@Component({
  selector: 'app-ourmission',
  templateUrl: './ourmission.component.html',
  styleUrls: ['./ourmission.component.css']
})
export class OurmissionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    //this.ngLoadJquery();

  }

  ngLoadJquery() {
    /* ==================================================
      # Youtube Video Init
   ===============================================*/
    $('.player').mb_YTPlayer();

    /* ==================================================
        # Magnific popup init
     ===============================================*/
    $(".popup-link").magnificPopup({
      type: 'image',
      // other options
    });

    $(".popup-gallery").magnificPopup({
      type: 'image',
      gallery: {
        enabled: true
      },
      // other options
    });

    $(".popup-youtube, .popup-vimeo, .popup-gmaps").magnificPopup({
      type: "iframe",
      mainClass: "mfp-fade",
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false
    });
  }
}
