<!-- Start Banner
     ============================================= -->
<div class="banner-area inc-indicator content-less text-large">
  <div id="bootcarousel" class="carousel text-light slide carousel-fade animate_text" data-ride="carousel">

    <!-- Indicators for slides -->
    <!--<div class="carousel-indicator">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <ol class="carousel-indicators">
              <li data-target="#bootcarousel" data-slide-to="0" class="active"></li>
              <li data-target="#bootcarousel" data-slide-to="1"></li>
              <li data-target="#bootcarousel" data-slide-to="2"></li>
              <li data-target="#bootcarousel" data-slide-to="3"></li>
              <li data-target="#bootcarousel" data-slide-to="4"></li>
              <li data-target="#bootcarousel" data-slide-to="5"></li>
            </ol>
          </div>
        </div>
      </div>
    </div>-->
    <!-- Wrapper for slides -->
    <div class="carousel-inner carousel-zoom">
      <div class="carousel-item active">
        <div class="slider-thumb bg-cover" style="background-image: url(assets/img/gallery/RN-1.jpg);"></div>
        <div class="box-table">
          <div class="box-cell shadow dark">
            <div class="container">
              <div class="row">
                <div class="col-lg-11">
                  <div class="content">
                    <h2 data-animation="animated slideInRight">Join us so we can make a difference <strong>In the world</strong></h2>
                    <p data-animation="animated slideInLeft">
                      At RehabNOVA, we believe that access to affordable, comprehensive healthcare is a right not a privilege.
                      We want to do our part to help more people stay healthy and lead a prosperous life.
                    </p>
                    <!--<a data-animation="animated fadeInUp" class="btn circle btn-light effect btn-md" href="javascript:void(0)">Discover More</a>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="slider-thumb bg-cover" style="background-image: url(assets/img/gallery/RN-2.jpg);"></div>
        <div class="box-table">
          <div class="box-cell shadow dark">
            <div class="container">
              <div class="row">
                <div class="col-lg-11">
                  <div class="content">
                    <!-- Slide 1-->
                    <h2 data-animation="animated slideInRight ">Help us Save<strong>Lives</strong></h2>
                    <p data-animation="animated slideInLeft">
                      Unfortunately, millions of Sub-Saharan Africans have no access to health care, leaving them more vulnerable than ever, especially in the midst of Covid-19 - the worst public health crisis the world has seen in recent times.
                    </p>
                    <!--<a data-animation="animated fadeInUp" class="btn circle btn-light effect btn-md" href="javascript:void(0)">Discover More</a>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="slider-thumb bg-cover" style="background-image: url(assets/img/gallery/RN-Dining-Exterior.jpg);"></div>
        <div class="box-table">
          <div class="box-cell shadow dark">
            <div class="container">
              <div class="row">
                <div class="col-lg-11">
                  <div class="content">
                    <!-- Slide 2-->
                    <h2 data-animation="animated slideInRight">Take part in <strong>what we do</strong></h2>
                    <p data-animation="animated slideInLeft">
                      We believe in the power of collaboration. Join us so we can collectively improve communities' standards of living.
                    </p>
                    <!--<a data-animation="animated fadeInUp" class="btn circle btn-light effect btn-md" href="javascript:void(0)">Discover More</a>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="slider-thumb bg-cover" style="background-image: url(assets/img/gallery/RN-Health-Overal.jpg);"></div>
        <div class="box-table">
          <div class="box-cell shadow dark">
            <div class="container">
              <div class="row">
                <div class="col-lg-11">
                  <div class="content">
                    <!-- Slide 3-->
                    <h2 data-animation="animated slideInRight">Join us to Build <strong>Thriving Communities</strong></h2>
                    <p data-animation="animated slideInLeft">RehabNOVA is embarking on creating Built Environment Models of Comprehensive, Self-sustaining, and Scalable Transformation Centers.</p>
                    <!--<a data-animation="animated fadeInUp" class="btn circle btn-light effect btn-md" href="javascript:void(0)">Discover More</a>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="slider-thumb bg-cover" style="background-image: url(assets/img/gallery/RN-House-Exterior.jpg);"></div>
        <div class="box-table">
          <div class="box-cell shadow dark">
            <div class="container">
              <div class="row">
                <div class="col-lg-11">
                  <div class="content">
                    <h2 data-animation="animated slideInRight">Our Operational <strong>Model</strong></h2>
                    <p data-animation="animated slideInLeft">
                      We aim at creating Self-Sustaining Centers that depend on their own resources to fund daily operations.
                    </p>
                   <!-- <a data-animation="animated fadeInUp" class="btn circle btn-light effect btn-md" href="javascript:void(0)">Discover More</a>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="slider-thumb bg-cover" style="background-image: url(assets/img/banner/1.jpg);"></div>
        <div class="box-table">
          <div class="box-cell shadow dark">
            <div class="container">
              <div class="row">
                <div class="col-lg-11">
                  <div class="content">
                    <h2 data-animation="animated slideInRight">Our Ultimate <strong>Goal</strong></h2>
                    <p data-animation="animated slideInLeft">
                      There are limited opportunities for vulnerable populations and the disenfranchised; especially disabled children, orphaned children, single mothers & women.  RehabNOVA’s ultimate goal is to  build comprehensive centers that provide health education, vocational training, agribusiness and related opportunities for those traditionally shut-out of labor and workforce.
                    </p>
                   <!-- <a data-animation="animated fadeInUp" class="btn circle btn-light effect btn-md" href="javascript:void(0)">Discover More</a>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- End Wrapper for slides -->
    <!-- Left and right controls -->
    <a class="left carousel-control light" href="#bootcarousel" data-slide="prev">
      <i class="fa fa-angle-left"></i>
      <span class="sr-only">Previous</span>
    </a>
    <a class="right carousel-control light" href="#bootcarousel" data-slide="next">
      <i class="fa fa-angle-right"></i>
      <span class="sr-only">Next</span>
    </a>

  </div>
</div>
<!-- End Banner -->
<app-theproblems></app-theproblems>
<app-ourvision></app-ourvision>
<app-ourmission></app-ourmission>
<app-ourteam></app-ourteam>
<app-testimonial></app-testimonial>
<app-partners></app-partners>
