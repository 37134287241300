<!-- Start The Problems
    ============================================= -->
<div class="mission-area half-bg default-padding-bottom bg-gray" style="margin-top: 50px;" >
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 info">
        <h5>The Problems</h5>
        <h2 class="text-blur">Problems</h2>
        <ul style="margin-top: 55px;">
          <li>Societal stigma and rejection of the most vulnerable - disabled children, orphaned children, single mothers & women.</li>
          <li>Minimal opportunities for vulnerable populations and high numbers of disenfranchised are a major concern for development in sub-Saharan Africa</li>
          <li>Great disparities in female educational opportunities.</li>
          <li>Lack of basic health services.</li>
          <li>Need for economic empowerment, training and engagement with the most disenfranchised.</li>
          <li>Need to stimulate economic activity and enterprise.</li>
          <li>Need for education, training, and related opportunities for those traditionally shut-out of labor and workforce.</li>
          <li>Need for agriculture and natural resources’ development to promote food security, nutrition, and sustainability through un-conventional means.</li>
          <li>Need for multi-faceted strategies that leverage strengths across sectors for long-term sustainable development.</li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- End The Problems -->
