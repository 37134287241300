export class StatusEnum {
  StatusEnum() {
  }
  LoadConfigurations(configObj:any) {
    EnumModel.Enum = configObj;
  }
}

export class EnumModel {
  static Enum = {
    STATUS_TYPE: { "Error": 0, "Success": 1, "Exception": 2, "NotFound": 4 },
  }
}
