<!-- Start Our Vision
============================================= -->
<div class="mission-area half-bg default-padding-bottom bg-gray">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 thumb-box">
        <div class="thumb">
          <div class="thumb-1">
            <img src="assets/img/banner/RN-vission-3.jpg" alt="Thumb">
          </div>
          <div class="thumb-2">
            <!--<img src="assets/img/about/RN2.jpg" alt="Thumb">
            <a href="https://drive.google.com/file/d/1NJ2TLi1YIH8bw-6TKI9VCuSiW6BgP6Yd/preview" aria-label="Rehab About Us" class="popup-youtube light video-play-button item-center">
              <i class="fa fa-play"></i>
            </a>-->
            <img src="https://i1.ytimg.com/vi/Z1nblu_FeuQ/mqdefault.jpg" alt="Thumb">
            <a href="https://www.youtube.com/watch?v=Z1nblu_FeuQ" aria-label="Rehab About Us" class="popup-youtube light video-play-button item-center">
              <i class="fa fa-play"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-6 info">
        <h5>Our Vission</h5>
        <h2 class="text-blur">Vission</h2>
        <h2 class="area-title">To help build prosperous communities.</h2>
        <!--<p>
          The organization’s vision is to help build prosperous communities across Sub-Saharan Africa, where people have a say in their communities and creativity, innovation and independence are promoted.
        </p>-->
        <p>
          The organization’s vision is to help build prosperous communities across Sub-Saharan Africa by promoting creativity, innovation and independence so people can have a say in their communities.
        </p>
        <p>
          As an organization, we aspire to be the catalyst that advances entrepreneurship by promoting agricultural cooperatives and building health and educational centers, but also addressing the underlying economic factors to achieve long-term
          sustainable positive outcomes.
        </p>
      </div>
    </div>
  </div>
</div>
<!-- End Our Vision -->
