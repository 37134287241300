<!-- Start Our Mission
============================================= -->
<div class="mission-area half-bg default-padding-bottom bg-gray">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 info">
        <h5>Our Mission</h5>
        <h2 class="text-blur">Mission</h2>
        <h2 class="area-title">To build and equip health and transformation centers.</h2>
        <p>
          RehabNOVA International is a non-governmental organization (NGO) giving priority care to vulnerable Sub-Saharan African communities, mainly focusing on women, the disabled, and orphans.
        </p>
        <p>
          Our mission is to build and equip health and transformation centers that address urgently needed primary care, as well as empower vulnerable citizens with skills necessary for improving their standard of living and promoting independence.
        </p>
      </div>
      <div class="col-lg-6 thumb-box">
        <div class="thumb">
          <div class="thumb-1">
            <img src="assets/img/banner/RN-Mission-1.jpg" alt="Thumb">
          </div>
          <div class="thumb-2">
            <!--<img src="assets/img/banner/RN-Mission-2.jpg" alt="Thumb">
            <a href="https://drive.google.com/file/d/1Euq4s7X_pwigMEfUVrYMZsFBS97gXQhL/preview" class="popup-youtube light video-play-button item-center"><i class="fa fa-play"></i></a>-->

            <img src="https://i1.ytimg.com/vi/m1R84aKSLdI/mqdefault.jpg" alt="Thumb">
            <a href="https://www.youtube.com/watch?v=m1R84aKSLdI" aria-label="Rehab About Us" class="popup-youtube light video-play-button item-center">
              <i class="fa fa-play"></i>
            </a>

          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Our Mission -->
