import { Injectable } from "@angular/core";
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { EncrDecrService } from "./encr-decr.service";
import { ConfigurationHelper } from "../helpers/configuration-helper";
import { environment } from "../../environments/environment"
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  //httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  constructor(private encrDecr: EncrDecrService, private router: Router, private httpClient: HttpClient) {
  }  

  login(loginModel: any) {
    return this.httpClient.post(environment.domain + "api/account/login", loginModel, this.setRequestHttpHeadersForWithoutToken()).pipe(map(data => { return data; }));
  }


  /**
   *logout and removes user profile from local storage
   * */
  logout() {
    localStorage.removeItem(ConfigurationHelper.Configuration.USER_PROFILE);
    this.router.navigate(['/home']);
  }

  /**
   * getCurrentUser
   * to get current logged in user*/
  getCurrentUser(): any {
    let encryptResult = this.encrDecr.get(environment.encryptkey, localStorage.getItem(ConfigurationHelper.Configuration.USER_PROFILE));
    let userProfile = JSON.parse(encryptResult!);
    if (userProfile != null && userProfile.AccessToken != null) {
      return userProfile;
    }
  }

  setRequestHttpClientHeaders() {
    let decryptResult = this.encrDecr.get(environment.encryptkey, localStorage.getItem(ConfigurationHelper.Configuration.USER_PROFILE));
    let httpOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
        .set('authorization', 'Bearer ' + JSON.parse(decryptResult!).AccessToken)
    };
    return httpOptions;
  }

  setRequestHttpHeadersForWithoutToken() {
    let httpOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/json').append('Access-Control-Allow-Origin','*')
    };
    return httpOptions;
  }

  setRequestHeadersForUpload() {
    let decryptResult = this.encrDecr.get(environment.encryptkey, localStorage.getItem(ConfigurationHelper.Configuration.USER_PROFILE));
    let httpOptions = {
      headers: new HttpHeaders().set('Accept', 'application/json')
        .set('authorization', 'Bearer ' + JSON.parse(decryptResult!).AccessToken)
    };
    return httpOptions;
  }

  setRequestHeaders() {
    var currentUser = this.getCurrentUser();
    if (currentUser != null) {
      var token = currentUser.AccessToken;
      let httpOptions = {
        headers: new HttpHeaders().set('Content-Type', 'application/json')
          .set('authorization', 'Bearer ' + token)
      };
      return httpOptions;
    }
    else {
      this.router.navigate(["/pages"]);
    }
  }

  setRequestHeadersForDownload(): any {

    let decryptResult = this.encrDecr.get(environment.encryptkey, localStorage.getItem(ConfigurationHelper.Configuration.USER_PROFILE));
    let httpOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
        .set('authorization', 'Bearer ' + JSON.parse(decryptResult!).AccessToken), responsetype: Blob
    };
    return httpOptions;
  }
}
