import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { PagesService } from './pages/pages.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

//import { ToastrModule } from 'ngx-toastr';
//import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { ExceptionhandlerService } from './services/exceptionhandler.service';
import { EncrDecrService } from './services/encr-decr.service';
import { AuthService } from './services/auth.service';
import { FormsModule } from '@angular/forms';
import { ScriptLoaderService } from './services/script-loader.service';
import { NavigationEnd, Router } from '@angular/router';
import { TruncateTextPipe } from './truncate-text.pipe';


@NgModule({
  declarations: [
    AppComponent,
    TruncateTextPipe,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    //ToastrModule.forRoot()
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      enableHtml: true,
      closeButton: true,
      timeOut: 3000,
      tapToDismiss: false }),
  ],
  exports: [HttpClientModule],
  providers: [PagesService, ExceptionhandlerService, EncrDecrService, AuthService, ScriptLoaderService],
  bootstrap: [AppComponent]
})
export class AppModule { }
