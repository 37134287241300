export const environment = {
  //production: true,
  //domain: window["apidomain_dev"],
  //appdomain: window["appdomain_dev"],
  //encryptkey: '123456$#@$^@1ERF'
  production: true,
  //dev
  //domain: 'https://rehabnovaapi.psdevhost.com/',
  //appdomain: 'https://rehabnova.psdevhost.com',
  //live
  domain: 'https://api.rehabnova.org/',
  appdomain: 'https://rehabnova.org',

  encryptkey: '123456$#@$^@1ERF'
};
